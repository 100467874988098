<template>
  <section>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-row class="justify-content-end">
            <b-col cols="6">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input :placeholder="$t('Common.Search')" v-model="search" />
              </b-input-group>
            </b-col>
            <b-col cols="3">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="w-100"
                @click="
                  () => {
                    $router.push('/masters/langunit-form/new');
                  }
                "
                v-if="$can('write', 'masters')"
              > 
                {{ $t("Language Unit Master List.Add Lang") }}
              </b-button>
            </b-col>
          </b-row>

          <div class="box mt-2">
            <b-table
              striped
              hover
              responsive
              class="position-relative"
              primary-key="id"
              empty-text="No matching records found"
              show-empty
              :per-page="pagination.perPage"
              :current-page="pagination.currentPage"
              :items="LanguageUnitLists"
              :fields="fields"
            >
            <template #head()="data">{{ $t(data.label) }}</template>
              <template #cell(langunit_is_active)="data">
                <b-form-checkbox
                  name="check-button"
                  switch
                  inline
                  :checked="data.item.langunit_is_active == 1 ? true : false"
                  @change="activateLanguageUnit(data.item)"
                 v-if="$can('write', 'masters')"
                />
                <span v-else>
                  <span :class="data.item.langunit_is_active?'badge text-capitalize badge-light-success badge-pill':'badge text-capitalize badge-light-danger badge-pill'">{{data.item.langunit_is_active?'Active':'In-Active'}}</span>
                </span>
              </template>
              <template #cell(edit)="data">
                <feather-icon
                  icon="EditIcon"
                  @click="
                    () => {
                      $router.push(`/masters/langunit-form/${data.item.langunit_id}`);
                    }
                  "
                  v-if="$can('write', 'masters')"
                />
                <feather-icon
                v-else
                
                  icon="EyeIcon"
                  @click="
                    () => {
                      $router.push(`/masters/langunit-form/${data.item.langunit_id}`);
                    }
                  "
                />
              </template>
            </b-table>
          </div>

          <b-card class="d-flex justify-content-between flex-wrap pt-0">
            <!-- pagination -->
            <div>
              <b-pagination
                v-model="pagination.currentPage"
                :total-rows="allLanguageUnitLists.length"
                :per-page="pagination.perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-card>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BInputGroupPrepend,
  BCard,
  BTable,
  BInputGroupAppend,
  BButton,
  BButtonGroup,
  BDropdown,
  BRow,
  BCol,
  BContainer,
  BFormGroup,
  BPagination,
  BInputGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import MasterServices from "@/apiServices/MasterServices";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    FeatherIcon,
    BInputGroupPrepend,
    BCard,
    BTable,
    BInputGroupAppend,
    BButton,
    BButtonGroup,
    BDropdown,
    BRow,
    BCol,
    BContainer,
    BFormGroup,
    BPagination,
    BInputGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
  },
  data() {
    return {
      search: "",
      pagination: {
        currentPage: 1,
        perPage: 50,
      },
      fields: [
        {
          key: "langunit_id",
          label: "Language Unit Master List.LANGUAGE UNIT ID",
          sortable: true,
        },
        {
          key: "langunit_name",
          label: "Language Unit Master List.LANGUAGE UNIT NAME",
          sortable: true,
        },
        {
          key: "langunit_code",
          label: "Language Unit Master List.Language Unit Code",
          sortable: true,
        },
        {
          key: "langunit_is_active",
          label: "Common.Status",
        },
        {
          key: "edit",
          label: "Common.Edit",
        },
      ],
      allLanguageUnitLists: [],
    };
  },
  computed: {
    LanguageUnitLists: function () {
      let arr = this.allLanguageUnitLists;
      let search = this.search;
      if (search) {
        search = search.toLowerCase();
        arr = arr.filter((item, index) => {
          let langunit_name = item.langunit_name;
          let langunit_code = item.langunit_code;
          langunit_name = langunit_name.toLowerCase();
          if (
            (langunit_name && langunit_name.includes(search)) ||
            (langunit_code && langunit_code.toLowerCase().includes(search))
          ) {
            return true;
          }
          return false;
        });
      }
      return arr;
    },
  },
  methods: {
    getAllLanguageUnitsList() {
      MasterServices.getAllLangunit()
        .then((response) => {
          if (response.data.status) {
            this.allLanguageUnitLists = response.data.data;
            // console.log(this.allLanguageUnitLists);
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || "Validation Failed",
                icon: response.data.icon,
                variant: response.data.variant,
              },
            });
          }
        })
        .catch((error) => {
          console.log("Fetching Language Unit Master ", error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Server Error",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        });
    },
    activateLanguageUnit(item) {
      let paylod = {
        langunit_id: item.langunit_id,
        status: item.langunit_is_active ? false : true,
      };
      MasterServices.setLangunitStatus(paylod)
        .then((response) => {
          if (response.data.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Language Unit Status is Updated SuccessFully.",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.getAllLanguageUnitsList();
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || "Validation Failed",
                icon: response.data.icon,
                variant: response.data.variant,
              },
            });
          }
        })
        .catch((error) => {
          console.log("Fetching Language Unit Master ", error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Server Error",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        });
    },
  },
  beforeMount() {
    this.getAllLanguageUnitsList();
  },
};
</script>
